<template>
  <div class="bbb">
    <div id="Interrogation">
      <van-nav-bar title="问诊中心" left-arrow @click-left="onClickLeft" />
      <van-search v-model="value" @input="searchChange" placeholder="" />
      <van-nodata
        v-if="list.length == 0"
        :figure="imgUrl[1]"
        title="暂无订单信息"
      />
      <div v-else class="data-order">
        <div class="margin_top item" v-for="(item, index) of list">
          <div class="title display jcsb aic">
            <div class="title_name">
              <span class="title_icon"></span>
              <div style="flex: 1">{{ consultType[item.consultType - 1] }}</div>
              <b> {{ item.stateName }} </b>
            </div>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">历史用药：</span>
            <span
              class="Medication color_20d8de"
              v-for="(t, i) of item.DrugsList"
              >{{ t.name }}</span
            >
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication">{{ item.created_at }}</span>
            </div>
          </div>
          <div class="operate">
            <van-button
              v-if="item.pay_state !== '0'"
              color="#1557FF"
              size="small"
              @click="refund(item)"
              class="message_btn"
              >申请退款</van-button
            >
          </div>
        </div>
      </div>
    </div>

    <footer_ />
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
import mixin from '@/utils/mixin';
import method from '../assets/js/util.js';
export default {
  mixins: [mixin],
  components: {
    footer_,
  },
  data() {
    return {
      list: [],
      consultType: ['图文咨询', '电话咨询', '视频咨询', '复方购药', '急速问诊'],
      value: '',
      listall:[],
      date: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    searchChange(){
      let list = []
      this.listall.forEach(element => {
        console.log(element.DrugsList)
        element.DrugsList.forEach(item => {
          if(item.name.indexOf(this.value) !==-1){
            list.push(element)
          }
        });
      });
      this.list = list
    },
    loadList(
      data = { query: { found_id: localStorage.getItem('userrole_id') } }
    ) {
      var d = new Date().getTime();
      var currentDate = new Date();

      // 将当前日期设置为当月的第一天
      currentDate.setDate(1);

      // 获取当月第一天的日期
      var firstDayOfMonth = currentDate.toISOString().slice(0, 10);
      this.date = [
        firstDayOfMonth + ' 00:00:01',
        method.formatDate(d).slice(0, 10) + ' 23:59:59',
      ];
      this.$api.article
        .query(
          {
            search_db: 'Pres',
            query_type: 'equal',
            start_time: this.date[0],
            end_time: this.date[1],
          },
          data
        )
        .then((res) => {
          res.data.result.forEach((element) => {
            element.pres_body = JSON.parse(element.pres_body);

            element['DrugsList'] = [element.pres_body.Drugs[0]];
            let pay_state = [
              '待支付',
              '已支付待接受',
              '确认接受待咨询',
              '咨询中',
              '已完成',
              '取消订单',
              '未支付已过期',
              '订单咨询已过期',
              '',
              '拒绝接单',
              '退款成功',
            ];
            element['stateName'] = '';
            if (element.doctorId == 'None') {
              element.stateName = '待接单';
              return;
            }
            if (element.pres_id == 'None') {
              element.stateName = '待申请';
              return;
            }
            if (element.pay_state == '0') {
              element.stateName = pay_state[element.pay_state * 1];
              return;
            }
            if (element.state == 'adopt') {
              element.stateName = '已完成';
              return;
            }

            if (
              (element.pay_state * 1 > 4 && element.pay_state * 1 <= 10) ||
              element.pay_state == '2' ||
              element.pay_state == '1'
            ) {
              element.stateName = pay_state[element.pay_state * 1];
            } else {
              if (element.pay_state == '4') {
                if (element.pharmaciststatus == '2') {
                  element.stateName = '已完成';
                } else {
                  element.stateName = '问诊被结束';
                }
              } else if (element.pay_state == '3') {
                if (element.pharmacistStatus == '3') {
                  element.stateName = '医生重新开方中';
                } else {
                  let doctorCheckStatusAll = [
                    '待问诊',
                    '问诊中',
                    '已完成',
                    '问诊失败',
                  ];
                  element.stateName =
                    doctorCheckStatusAll[element.doctorCheckStatus];
                }
              }
            }
            // element.pres_body = JSON.parse(element.pres_body)
            // element['DrugsList'] = element.pres_body.Drugs
            // console.log( element.pres_body)
            // element['stateName'] = ''
            // if(element.doctorId =='None'){
            //   element.stateName = '待接单'
            //   return
            // }
            // if(element.pay_state  == '0'){
            //   element.stateName = '待支付'
            //   return
            // }
            // if(element.pay_state  == '10'){
            //   element.stateName = '退款成功'
            //   return
            // }
            // if(res.data.result.status == 0){
            //   element.stateName = statusAll[res.data.result.status]
            //   return
            // }
            // if(element.doctorId  == 'None'){
            //   element.stateName = '待接单'
            //   return
            // }
            // if(element.state == 'adopt'){
            //   element.stateName = '已完成'
            // }else{
            //   if(element.pharmacistStatus != '0'){
            //       if(element.pharmacistStatus == '3'){
            //       element.stateName = '药师审核不通过'
            //     }else if(element.pharmacistStatus == '1' || element.pharmacistStatus == '2'){
            //       element.stateName = '药师审核中'
            //     }
            //   }else{
            //     let doctorCheckStatusAll = [
            //         '待问诊',
            //         '问诊中',
            //         '已完成',
            //         '问诊失败',
            //       ]
            //       element.stateName = doctorCheckStatusAll[element.doctorCheckStatus]
            //   }

            // }
          });
          this.list = res.data.result;
          this.listall =  res.data.result
        });
    },
    tj() {
      this.$router.push('/post_Patient');
    },
    refund(item) {
      let pres_id = item.pres_id;
      let that = this;
      this.$api.article.YBPayImg(pres_id, 2, item.ih_type).then((res) => {
        console.log(res);
        if (res.data.result.data) {
          this.$alert('请确认是否退款', '提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              sessionStorage.removeItem('Drugs');
              sessionStorage.removeItem('Current');
              sessionStorage.removeItem('disease');
              sessionStorage.removeItem('patientId');
              window.location.href = res.data.result.data;
            },
          });
        } else {
          this.$message(res.data.result.message);
        }
      });
    },
  },
  created() {
    this.loadList();
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.data-order{
  height: calc(100vh - 160px);
  overflow-y: scroll
}
.van-nav-bar__title {
  color: #111 !important;
  font-weight: 600 !important;
}

.bbb {
  min-height: 100vh;
  background: linear-gradient(to bottom, #eef4fd, #f6f6f6);
}

.PatientBtn {
  margin-top: 10rem !important;
}
.van-button--small {
  min-width: 100px;
}

.item {
  padding: 0.97rem 0.91rem 1.19rem;
  background: #ffffff;
  border-radius: 0.69rem;

  .title_name {
    font-family: SourceHanSansCN;
    font-weight: bold;
    font-size: 0.94rem;
    color: #282828;
    width: 100%;
    display: flex;
    align-items: center;

    b {
      color: #e65e5e;
    }
  }

  .title_icon {
    margin-right: 0.41rem;
    display: inline-block;

    width: 0.19rem;
    height: 0.81rem;
    background: #1557ff;
    border-radius: 0.09rem;
  }

  .operate {
    margin-top: 1.31rem;
    padding: 0.69rem 0.47rem 0 0.75rem;
    border-top: 0.06rem solid #d3d8e0;
  }
}

.padding_lr_4_1 {
  padding-left: 0.41rem;
  padding-right: 0.41rem;
}
</style>
